import React, { useState, useContext } from 'react'
import { GlobalDataContext } from '../../context/context'

function Accordion() {
    const [accordion, setAccordion] = useState(true)
    const [accordion1, setAccordion1] = useState(true)
    const [accordion2, setAccordion2] = useState(true)
    const [accordion3, setAccordion3] = useState(true)
    const handleClickAccor = () => {
        return setAccordion(!accordion)
    }
    const handleClickAccor1 = () => {
        return setAccordion1(!accordion1)
    }
    const handleClickAccor2 = () => {
        return setAccordion2(!accordion2)
    }
    const handleClickAccor3 = () => {
        return setAccordion3(!accordion3)
    }
    const { rpdata } = useContext(GlobalDataContext);
    return (
        <div className='w-full h-auto block items-center' >

            <div className='bg-1 w-full h-auto py-3 px-8 rounded-xl mb-3' onClick={handleClickAccor}>
                <h2 className='text-[35px] text-white'>
                {rpdata?.dbServices?.[4]?.name}
                </h2>
            </div>

            <div className={`mb-6 ${!accordion ? 'block' : 'hidden'}`}>
                <div className="p-5 font-light border rounded-b-xl border-t-0 border-gray-200 dark:border-gray-700">
                    <p>{rpdata?.dbServices?.[4]?.description[0]?.text}</p>
                </div>
            </div>
            <div className='bg-1 w-full h-auto py-3 px-8 rounded-xl mb-3' onClick={handleClickAccor3}>
                <h2 className='text-[35px] text-white'>
                {rpdata?.dbServices?.[7]?.name}
                </h2>
            </div>

            <div className={`mb-6 ${!accordion3 ? 'block' : 'hidden'}`}>
                <div className="p-5 font-light border rounded-b-xl border-t-0 border-gray-200 dark:border-gray-700">
                    <p>{rpdata?.dbServices?.[7]?.description[0]?.text}</p>
                </div>
            </div>
            <div className='bg-1 w-full h-auto py-3 px-8 rounded-xl mb-3' onClick={handleClickAccor1}>
                <h2 className='text-[35px] text-white'>
                {rpdata?.dbServices?.[5]?.name}
                </h2>
            </div>

            <div className={`mb-6 ${!accordion1 ? 'block' : 'hidden'}`}>
                <div className="p-5 font-light border rounded-b-xl border-t-0 border-gray-200 dark:border-gray-700">
                    <p>{rpdata?.dbServices?.[5]?.description[0]?.text}</p>
                </div>
            </div>
            <div className='bg-1 w-full h-auto py-3 px-8 rounded-xl mb-3' onClick={handleClickAccor2}>
                <h2 className='text-[35px] text-white'>
                {rpdata?.dbServices?.[6]?.name}
                </h2>
            </div>

            <div className={`mb-6 ${!accordion2 ? 'block' : 'hidden'}`}>
                <div className="p-5 font-light border rounded-b-xl border-t-0 border-gray-200 dark:border-gray-700">
                    <p>{rpdata?.dbServices?.[6]?.description[0]?.text}</p>
                </div>
            </div>

            
        </div>
    )
}

export default Accordion